import React from 'react'
import yellowDot from './img/yellow_dot.png'

import curriculum from './img/curriculum.svg'
import k12 from './img/k-12.svg'
import exam from './img/exam.svg'
import enrollment from './img/enrollment.svg'

const content = [
	{
		"title": "K-12 SUBJECTS",
		"description": "Studying isn’t easy, and self-organizing is hard. As your Study Buddy I’m sitting on the bench right next to you, guiding you through your tasks, and helping you to advance effortlessly.",
		"points": [
			"K-12 Mathematics",
			"K-12 Language Arts",
			"K-12 Social Studies",
			"Biology/Chemistry"
		],
		src: k12
	},
	{
		"title": "STUDY BUDDY and Academic Tutoring",
		"description": "Your job is to focus on learning, my job is to help you excel. Get immediate assistance with homework assignments to ensure you stay on track and understand the material.",
		"points": [
			"Homework/Project help",
			"Essay Prep & Editing",
			"Study & Organizational skills"
		],
		src: curriculum
	},
	{
		"title": "EXAM PREP and State Standardized Testing",
		"description": "Gear up for your exams & improve your test skills! As a highly trained and experienced teacher, I help you to prepare for test day and ace your exams.",
		"points": [
			"PSAT",
			"SAT",
			"ACT",
			"GED",
			"GRE"
		],
		src: exam
	},
	{
		"title": "COLLEGE PREP and Admissions Counseling",
		"description": "Starting college is a real challenge - you might feel intimidated, or don’t know where to start. I’m an experienced academic counselor, and understand your struggles on a personal level.",
		"points": [
			"College Admissions & Application Preparation",
			"Resume/ Essay Preparation & Editing",
			"Study skills & self-organization"
		],
		src: enrollment
	}
]

const MiniSection = ({ title, description, points, src, index }) => {
    const backgroundStyle = {
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        border: '2px solid black',
    }

    return (
        <section className="mb-16">
            <div className="title-academic">
                <div className="h3">{title}</div>
            </div>
            <div className={`w-full justify-center ${index % 2 === 0 && 'squiggly'}`}>
                <div className={`w-full flex ${index % 2 === 0 ? 'flex-col' : 'flex-col-reverse'} border-b-2 border-black sm:border-none sm:flex-row space-between text-lg px-8`}>
                    {index % 2 === 0 && (
                        <div className="w-full sm:w-1/2 flex justify-center mt-8 max-h-[400px]">
                            <div className="mx-auto w-full aspect-square max-w-[400px] sm:h-[300px] sm:w-[300px] 2xl:h-[400px] 2xl:w-[400px] rounded-full overflow-hidden shadow-[15px_15px_1px_0_rgba(199,241,255,1)]" style={backgroundStyle}></div>
                        </div>
                    )}
                    <div className="w-full sm:w-1/2 items-center sm:px-16 flex-col sm:my-auto mt-8">
                        <p className="mb-8 text-xl text-gray-800">{description}</p>
                        <ul style={{ listStyle: 'disc', color: 'black' }} className="pl-8 pb-8">
                            {points.map((point, index) => (
                                <li key={`point_${index}`}>{point}</li>
                            ))}
                        </ul>
                    </div>
                    {index % 2 === 1 && (
                        <div className="w-full sm:w-1/2 flex justify-center mt-8 max-h-[400px]">
                            <div className="mx-auto w-full aspect-square max-w-[400px] sm:h-[300px] sm:w-[300px] 2xl:h-[400px] 2xl:w-[400px] rounded-full overflow-hidden shadow-[15px_15px_1px_0_rgba(199,241,255,1)]" style={backgroundStyle}></div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}


export const ServicesSection = () => {
	return (
		<section className="flex-col w-full xl:w-2/3 mx-auto">
			<div className="text-2xl flex justify-center m-0 mt-16 mx-auto">
				<img src={yellowDot} style={{ width: 50, height: 50 }} alt="" />
				<div className="h2">Master your academic goals</div>
			</div>
			<div className="w-full flex-col justify-center">
				{content.map((item, index) => {
					const { title, description, points, src } = item
					return <MiniSection index={index} title={title} description={description} points={points} src={src} />
				})}
			</div>
		</section>
	)
}
import React from 'react';

const MyServices = () => {
	return (
		<section className="py-16 bg-gray-100">
			<div className="title-academic text-center mb-8">
				<h2 className="h3 text-3xl font-bold text-gray-900">My Services</h2>
			</div>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<div className="flex flex-col justify-center items-center text-center py-8 px-6 mb-8 bg-white rounded-md border-2 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)]">
						<h3 className="text-3xl font-bold text-gray-900 mb-4">One-on-One Tutoring</h3>
						<p className="text-gray-700 mb-4">
							Receive personalized attention and customized lessons in subjects ranging from math and science to language arts and test preparation.
						</p>
					</div>

					<div className="flex flex-col justify-center items-center text-center py-8 px-6 mb-8 bg-white rounded-md border-2 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)]">
						<h3 className="text-3xl font-bold text-gray-900 mb-4">Group Tutoring</h3>
						<p className="text-gray-700 mb-4">
							Benefit from collaborative learning in small group sessions that encourage discussion and peer support.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MyServices;

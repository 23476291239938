import React from 'react'

const GetStarted = () => {
	return (
		<section className="pt-16">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="text-center">
					<h2 className="h2">Get Started</h2>
					<p className="text-xl text-gray-700 mb-8">
						Take the first step towards academic success. Sign up for a free consultation and see how my stress-free tutoring services can make a difference.
					</p>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
					{/* Step 1 */}
					<div className="flex flex-col justify-center items-center text-center mb-8 py-8 px-6 bg-white rounded-md border-2 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)]">
						<div className="text-4xl text-gray-900 mb-4">1</div>
						<h3 className="text-xl font-bold text-gray-900 mb-4">Contact me</h3>
						<p className="text-gray-700 mb-4">
							Book a consultation call and tell me about your tutoring needs.
						</p>
					</div>

					{/* Step 2 */}
					<div className="flex flex-col justify-center items-center text-center mb-8 py-8 px-6 bg-white rounded-md border-2 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)]">
						<div className="text-4xl text-gray-900 mb-4">2</div>
						<h3 className="text-xl font-bold text-gray-900 mb-4">Schedule</h3>
						<p className="text-gray-700 mb-4">
							Choose a time that works best for you and start your sessions.
						</p>
					</div>

					{/* Step 3 */}
					<div className="flex flex-col justify-center items-center text-center mb-8 py-8 px-6 bg-white rounded-md border-2 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)]">
						<div className="text-4xl text-gray-900 mb-4">3</div>
						<h3 className="text-xl font-bold text-gray-900 mb-4">Succeed</h3>
						<p className="text-gray-700 mb-4">
							Watch your confidence and grades soar with my expert guidance in a relaxed, supportive environment.
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default GetStarted
import React from 'react'
import yellowDot from './img/yellow_dot.png'
import headshot from './img/headshot_color.jpg'
const HeaderSection = () => {
	return (
		<section className="hero-section container w-full static mt-[100px]">
			<div className="flex flex-col-reverse w-full lg:flex-row lg:w-4/5 lg:container mx-auto">
				<div className="hero-text w-full lg:w-2/3 z-20">
					<div className="div-block-6">
						<div className="subtitle">
							<img src={yellowDot} loading="lazy" height="30" alt="" width="66" className="vectors-wrapper" />
							<div className="body-2">Book Expert Help</div>
						</div>
						<div className="div-block">
							<div className="h1">
								<span className="h1-2">Your Personal<br /></span>
								<span className="text-span-2 text-sky-300">Online</span>
								<span className="h1-2"> Study Buddy</span>
							</div>
						</div>
						<div className="body-1">Personalized Online Classes & Group SAT Classes<br />College Admissions & Application Preparation</div>
					</div>
					<a href="https://calendly.com/sahilstudybuddy" target="_blank" rel="noreferrer" className="border-2 relative border-sky-200 rounded-full cursor-pointer w-[350px] h-[70px]">
						<div className="w-full h-full flex justify-center items-center font-bold text-xl text-gray-900 -mt-3 -ml-3 transition-all hover:mt-0 hover:ml-0 bg-sky-300 rounded-full ">Schedule A Consultation</div>
					</a>
				</div>
				<div className="w-full z-10 flex justify-center lg:justify-end my-8 ">
					{/* <div style={{ backgroundImage: `url(${headshot})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '43% center' }} className="w-full object-cover"></div> */}
					<img src={headshot} alt="Sahil Headshot" className="rounded-full object-cover" style={{ width: 'calc(30vw + 80px)', height: 'calc(30vw + 80px)', maxWidth: '700px', maxHeight: '700px' }} />
				</div>
			</div>
		</section>



	)
}

export default HeaderSection
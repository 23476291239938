import React from 'react'

const TwoColumnImageSection = ({ title, description, pills = [], callToActionDesc, callToActionText }) => {
	return (
		<div className="flex container my-8 sm:mb-32 sm:mt-0 mx-auto flex-col-reverse sm:flex-row">
			<div className="w-full sm:w-2/3">
				<img sizes="100vw"
					srcSet="/section_1_large.png"
					alt=""
					src="/section_1_large.png"
					loading="lazy" className="image-13" />
			</div>
			<div className="w-full sm:w-3/5 flex mt-8">
				<div className="p-4 justify-center">
					<img width="72" height="72" alt=""
						src="https://assets-global.website-files.com/655e500f5f4733e36a33ff4c/656e2fde087e4d5f8d7f2a71_Vectors-Wrapper.svg"
						loading="lazy" className="vectors-wrapper-7" />
					<div className="w-full">
						<div className="">
							<div className="">
								<div className="sub-4">{title}</div>
								<div className="text-3">{description}</div>
							</div>

							<div className="frame-427319264">
								{pills.map((text, i) => {
									return (
										<div key={`pill_${i}`} className="pill">
											<div className="tag">{text}</div>
										</div>
									)
								})}
								{/* <div className="tag">And this kind too</div> */}
							</div>
						</div>
						{callToActionText && (
							<div className="frame-427319267">
								<div className="body-8">{callToActionDesc}</div>
								<div className="button">
									<p className="button-press-copy w-button">{callToActionText}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TwoColumnImageSection
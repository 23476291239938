import React, { useState } from 'react'

const FAQsSection = () => {
	const faqs = [
		{ q: `Can I start lessons any time?`, a: `Yes, of course. Contact me to schedule your first lesson.` },
		{ q: `Do I need to subscribe to set lessons?`, a: `No, we can keep our schedule as flexible as needed.` },
		{ q: `What happens if I need to miss a class or cancel?`, a: `If you must miss a class or need to cancel we can reschedule if given a 48+ hour notice.` },
		{ q: `How much does it cost?`, a: `It depends on the services, lesson, location, and whether it will be 1:1 or a group session. Contact me to find out about hourly rates and prices.` },
		{ q: `How does payment work?`, a: `Classes will be paid upfront.` },
		{ q: `Do I need to buy any additional material or course?`, a: `No, but feel free to bring any outside material of your own.` },
		{ q: `How long is a lesson?`, a: `Each lesson is one hour or 30-minute lessons (only if needed).` },
		{ q: `How can I book a lesson?`, a: `Click here to book a lesson: sahilstudybuddy@gmail.com or sahil@mystudiesbuddy.com` },
		{ q: `Can I book a trial lesson?`, a: `Yes, you can book a 30-minute session that addresses outstanding questions, homework/test issues, or trouble subjects.I don’t offer trial lessons for college admissions counselling.` },
		{ q: `I have more questions, how can I reach you?`, a: `You can contact me here: sahilstudybuddy@gmail.com or sahil@mystudiesbuddy.com` }
	]

	const [expandedIndex, setExpandedIndex] = useState(null)

	const toggleAccordion = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index)
	}

	return (
		<section className="py-16">
			<div className="container-23">
				<div className="w-row">
					<div className="flex flex-col">
						<div className="h2">FAQs</div>
					</div>
					{faqs.map((faq, index) => (
						<div key={`faq_${index}`} className={`accordion w-dropdown`}>
							<div
								className="accordion-toggle w-dropdown-toggle"
								id={`accordion-collapse-heading-${index}`}
								onClick={() => toggleAccordion(index)}
								aria-expanded={expandedIndex === index}
								aria-controls={`accordion-collapse-body-${index}`}
							>
								<div className="text-block-15-fag">{faq.q}</div>
								<img
									src="https://assets-global.website-files.com/655e500f5f4733e36a33ff4c/656cd3737db825ff402630ec_plus.png"
									loading="lazy"
									width="14"
									height="14"
									alt=""
									className={`accordion-icon ${expandedIndex === index ? 'expanded' : ''}`}
								/>
							</div>
							<nav
								className={`w-dropdown-list ${expandedIndex === index ? 'dropdown-list' : ''}`}
								id={`accordion-collapse-body-${index}`}
								aria-labelledby={`accordion-collapse-heading-${index}`}
							>
								<div className="text-block-16">{faq.a}</div>
							</nav>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default FAQsSection

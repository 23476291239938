import React from 'react'

const FinalSection = () => {
	return (
		<section className="py-12">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
				<div className="mb-8">
					<h2 className="h2">Your Study Buddy</h2>
					<p className="text-xl mt-2 mb-8 sm:mb-0">Empower your learning journey with my expert tutoring services.</p>
					<p className="text-xl">Achieve your academic goals in a relaxed, supportive environment.</p>
				</div>
			</div>
		</section>
	)
}

export default FinalSection

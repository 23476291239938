import React from 'react'

const CallToAction = ({ title }) => {
	return (
		<a href="https://calendly.com/sahilstudybuddy" target="_blank" rel="noreferrer" className="flex justify-center my-16">
			<div className="border-2 relative border-sky-200 rounded-full cursor-pointer w-[350px] h-[70px]">
				<div className="w-full h-full flex justify-center items-center font-bold text-xl text-gray-900 -mt-3 -ml-3 transition-all hover:mt-0 hover:ml-0 bg-sky-300 rounded-full ">{title}</div>
			</div>
		</a>

	)
}

export default CallToAction
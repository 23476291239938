import FinalSection from './FinalSection';
import Footer from './Footer';
import { SixCardSection } from './SixCardSection';
import CallToAction from './components/CallToAction';
import CaoruselSection from './components/CarouselSection';
import FAQsSection from './components/FAQs';
import GetStarted from './components/GetStarted';
import HeaderSection from './components/HeaderSection';
import MyServices from './components/MyServices';
import { ServicesSection } from './components/ServicesSection';
import TwoColumnImageSection from './components/TwoColumnImageSection';

const PainPointsSection = () => {
	const PainPointsDesc = (<>
		<p className="text-gray-800 text-xl">With my study plans you will be well prepared, learn how to organize your learning schedule, and work on excellent grades - on a basic and advanced level.</p>
		<br />
		<p className="text-gray-800 text-xl">Whether you're aiming to boost your grades, ace your exams, or gain a deeper understanding of challenging subjects, I am here to help you succeed in a stress-free environment.</p>
	</>)

	return (
		<TwoColumnImageSection title="Personalized tutoring with a laidback approach" description={PainPointsDesc} />
	)
}

function App() {
	return (
		<>
			<HeaderSection />
			<PainPointsSection />
			<SixCardSection />
			<ServicesSection />
			<CallToAction title={"Schedule Now"}/>
			<MyServices />
			<GetStarted />
			<CallToAction title={"Book a Lesson"}/>
			<CaoruselSection />
			<FAQsSection />
			<CallToAction title={"Consulation Call"}/>
			<FinalSection />
			<Footer />
		</>
	);
}

export default App;

import React, { useRef } from 'react'

const data = [
	{ title: "Sarah K.", subtitle: "High School Senior", desc: "I was struggling with calculus, but Sahil made it so much easier to understand. My grades improved dramatically, and I never felt stressed out!" },
	{ title: "Michael T.", subtitle: "College Freshman", desc: "Thanks to the SAT prep course, I scored in the 95th percentile and got into my dream college. The sessions were so laidback, I actually enjoyed studying!" },
	{ title: "Lisa S.", subtitle: "Parent of an Elementary School Student", desc: "Finding the right tutor for my son was challenging until we discovered Sahil's service. His patient approach has made a world of difference. My son now looks forward to his sessions and has shown remarkable improvement in both his confidence and grades. The personalized attention and stress-free environment have been exactly what he needed." },
	{ title: "David H.", subtitle: "Parent of a Middle School Student", desc: "My daughter struggled with math and often felt stressed about her homework. Sahil not only helped her understand the material but did so in a way that was fun and engaging. The relaxed sessions took away the pressure, and now she's even excited about learning new, more challenging concepts. Her grades and attitude towards school have both improved significantly." },
	{ title: "Karen M.", subtitle: "Parent of a High Schooler (SATs)", desc: "Preparing for the SATs can be incredibly stressful, but Sahil's approach was perfect for my son. He provided challenging material when he was ready for it and never pushed him too hard. As a result, he felt confident and well-prepared, scoring higher than we had hoped. We couldn't be happier!" },
	{ title: "John P.", subtitle: "Parent of a College Student", desc: "My daughter was struggling with her college coursework and feeling overwhelmed. The sessions were tailored to her needs, challenging her appropriately without adding any unnecessary stress. She not only improved her grades but also started enjoying her studies again." },
	{ title: "Emily J.", subtitle: "High School Student", desc: "Before I started tutoring, I was really struggling with biology. Sahil made the sessions fun, which helped me relax and concentrate." },
	{ title: "Mark W.", subtitle: "College Student", desc: "Balancing college courses is tough, but Sahil's laidback style made it manageable. He provided just the right amount of challenge, pushing me to excel. I've been able to keep up with my coursework. Highly recommend!" },
	{ title: "Mrs. Taylor", subtitle: "High School Teacher", desc: "As a teacher, I've seen many students struggle with the pressure of learning. This tutoring service stands out because of its relaxed and supportive approach. Sahil knows how to challenge students just the right amount, ensuring they grow without feeling overwhelmed. I've seen firsthand the positive impact on my students' performance and confidence." },
]

const Card = ({ title, subtitle, description }) => {
	return (
		<div className="max-w-xs w-full bg-white shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-[2px] border-black rounded-lg overflow-hidden mx-auto mb-4">
			<div className="px-4 py-2">
				<h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
				<h2 className="text-lg font-semibold text-gray-500">{subtitle}</h2>
				<p className="mt-2 text-gray-600">{description}</p>
			</div>
		</div>
	)
}

const Carousel = ({ data }) => {
	const containerRef = useRef(null)

	return (
		<div className="flex flex-row w-full min-h-[450px]">
			<div className="flex flex-row absolute w-full overflow-hidden" ref={containerRef}>
				{data.map((item, index) => {
					return (
						<div key={index} className={`min-w-[300px] flex mx-4 animate-left`}>
							<Card title={item.title} subtitle={item.subtitle} description={item.desc} />
						</div>
					)
				}
				)}
			</div>
			<div className="flex flex-row absolute w-full overflow-hidden" ref={containerRef}>
				{data.map((item, index) => {
					return (
						<div key={`2_${index}`} className={`min-w-[300px] flex mx-4 animate-left-delay`}>
							<Card title={item.title} subtitle={item.subtitle} description={item.desc} />
						</div>
					)
				}
				)}
			</div>
		</div>
	)
}

const CarouselSection = () => {
	return (
		<section className="bg-gray-100 pt-16">
			<div className="text-2xl flex justify-center">
				<img src="https://assets-global.website-files.com/655e500f5f4733e36a33ff4c/655e60e20cfbb9faea11f059_Vectors-Wrapper.svg" width="50" height="50" alt="" className="-mt-8" />
				<div className="h2">What my Students Say</div>
			</div>
			<Carousel data={data} />
		</section>
	)
}

export default CarouselSection
import React from 'react'
import yellowDot from './components/img/yellow_dot.png'

export const SixCardSection = () => {
	return (
		<section className="bg-[radial-gradient(169.40%_89.55%_at_94.76%_6.29%,rgba(199,241,255,1)_0%,rgba(255,255,255,0.00)_100%)] py-16">
			<div className="text-2xl flex mt-16 justify-center">
				<div className="h2">Why Choose Me?</div>
				<img src={yellowDot} style={{width: 50, height: 50}} alt="" />
			</div>
			<div className="w-full flex-col justify-center">
				<div className="flex flex-col sm:flex-row w-full justify-center px-4">
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Customized learning plans</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">Every student is unique, and so is my approach. I create tailored learning plans that cater to your individual needs and learning style.</p>
					</div>
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Laidback, no-stress environment</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">I believe that learning should be enjoyable and pressure-free. My relaxed tutoring sessions are designed to make you feel comfortable and confident, without the stress.</p>
					</div>
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Expert tutor</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">As a highly trained teacher with 8 years of teaching experience, I provide live learning for a wide variety of stages and subjects, and set you up for academic success.</p>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row w-full justify-center px-4">
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Challenge without pressure</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">If the material feels too easy, I will gently push you to explore new and harder topics, ensuring you're always learning and growing without feeling overwhelmed.</p>
					</div>
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Flexible Scheduling</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">I offer flexible scheduling options to fit your busy life. Choose from in-person sessions or convenient online tutoring, available anytime, anywhere.</p>
					</div>
					<div className={`mx-auto w-full sm:w-1/3 border-black shadow-[5px_7px_0_2px_rgba(179,231,245,1)] border-2 p-4 sm:w-1/5 sm:mx-4 min-h-[250px] max-w-[350px] my-4 bg-white rounded-xl`}>
						<h2 className="text-xl text-center font-bold p-2">Proven success</h2>
						<p className="text-gray-700 text-lg sm:text-left text-center pt-4">My students have consistently achieved outstanding results, from improving their grades to gaining admission to colleges and universities.</p>
					</div>
				</div>
			</div>
		</section>
	)
}
import React from 'react';

const Footer = () => {
	return (
		<footer className="bg-gray-800 text-gray-200 py-12">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-cleftenter">
				<h2 className="text-2xl font-bold mb-8">MyStudiesBuddy.com</h2>

				<div className="flex flex-col">
					<div className="text-lg">
						<a href="https://www.linkedin.com/in/sahil-k-aneja/" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-white">LinkedIn</a>
					</div>
					<div className="text-lg">
						<p className="text-gray-400 hover:text-white">Privacy Policy</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
